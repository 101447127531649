var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-us"},[_c('div',{staticClass:"contact-us__content"},[_c('h2',{staticClass:"contact-us__title"},[_vm._v(_vm._s(_vm.$t('Send a message')))]),_vm._v(" "),_c('ValidationObserver',{staticClass:"col-12",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var validate = ref.validate;
var validated = ref.validated;
var dirty = ref.dirty;
return [_c('form',{staticClass:"contact-us__form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();validate().then(handleSubmit(_vm.handleFormSubmit(reset)))}}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"contact-us-name","placeholder":_vm.$t('Name'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"contact-us-email","placeholder":_vm.$t('Your email'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|min:10|phone","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"contact-us-phone","placeholder":_vm.$t('Your phone number'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.telephone"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfTextarea',{staticClass:"contact-us__comment",attrs:{"name":"contact-us-comment","rows":"4","maxlength":"300","placeholder":_vm.$t('Your message'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})]}}],null,true)}),_vm._v(" "),_c('SfButton',{staticClass:"sf-button sf-button--gray sf-button--medium contact-us__action",attrs:{"type":"submit"}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Send')))])])],1),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }