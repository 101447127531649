





















































































import { SfBreadcrumbs, SfButton, SfImage, SfInput, SfLoader, SfTextarea } from '~/components';
import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { useUiNotification, useApi } from "~/composables/";
import storeGetters from "~/modules/boutiques/getters/storeGetters";
import { submitBoutiqueContactFormMutation } from '~/modules/boutiques/queries/submitBoutiqueContactForm'

import { ValidationObserver, ValidationProvider, setInteractionMode, extend } from 'vee-validate';
import { email, min, required } from "vee-validate/dist/rules";
import { customerPhoneRegExp, invalidPhoneMsg } from '~/helpers/phoneValidation';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('email', {
  ...email,
  message: 'Enter a correct email',
});
extend('phone', {
  message: invalidPhoneMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPhoneRegExp.test(value),
});

export default defineComponent ({
  name: 'contactUs',
  components: {
    SfBreadcrumbs,
    SfButton,
    SfImage,
    SfInput,
    SfLoader,
    SfTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    landing: {
      type: [Object, Array],
      default: () => ({
        boutique_id: '',
      })
    }
  },
  setup(props) {
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app: { i18n }, $config, $recaptcha } = useContext();
    const { query } = useApi();
    const { send: sendNotification } = useUiNotification();

    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);
    let recaptchaToken = '';

    let contactUsSent = ref(false);

    type Form = {
      name: string,
      email: string,
      telephone: string,
      comment: string,
      recaptchaToken?: string,
      recaptchaInstance?: string,
    };

    type Response = {
      data: {
        submitBoutiqueContactForm: {
          message: string,
          success: boolean
        }
      }
    };

    const form = ref<Form>({
      name: '',
      email: '',
      telephone: '',
      comment: '',
    });

    const loading = ref(false);
    const handleFormSubmit = (reset: () => void) => async () => {

      try {
        loading.value = true;
        if (isRecaptchaEnabled.value) {
          $recaptcha.init();
          recaptchaToken = await $recaptcha.getResponse();
        }

        const response = await query(submitBoutiqueContactFormMutation, {
          boutiqueId: props.landing.boutique_id,
          input: {
            comment: form.value.comment,
            email: form.value.email,
            telephone: form.value.telephone,
            name: form.value.name,
          }
        }) as Response;

        form.value.name = '';
        form.value.email = '';
        form.value.telephone = '';
        form.value.comment = '';
        reset();

        if (response.data.submitBoutiqueContactForm.success) {
          sendNotification({
            id: Symbol('contact-us_success'),
            message: response.data.submitBoutiqueContactForm.message,
            type: 'success',
            icon: 'check',
            persist: false,
            title: i18n.t('Email Sent!') as string,
          });
        } else {
          sendNotification({
            id: Symbol('contact-us_error'),
            message: i18n.t('Something went wrong. Try it later.') as string,
            type: 'danger',
            icon: 'info_shield',
            persist: false,
            title: i18n.t('Error') as string,
          });
          console.error('Contact us request error: ', response.data.submitBoutiqueContactForm.message)
        }
        if (isRecaptchaEnabled.value) $recaptcha.reset();
        contactUsSent = ref(true);
      } catch (error) {
        console.error('Error: ', error)
      } finally {
        loading.value = false;
        if (isRecaptchaEnabled.value) {
          $recaptcha.reset();
        }
      }
    }

    return {
      form,
      handleFormSubmit,
      isRecaptchaEnabled,
      loading,
      ...storeGetters,
    };
  },
});
