export const submitBoutiqueContactFormMutation = `
  mutation SubmitBoutiqueContactForm(
    $boutiqueId: Int!,
    $input: ContactInput!
    ) {
    submitBoutiqueContactForm(boutique_id: $boutiqueId, input: $input) {
      success
      message
    }
  }
`
